<template>
  <div>
    <b-button @click="newIndustry" variant="link">New Industry</b-button>
    <b-table hover :items="industries" :fields="fields">
      <template #cell(actions)="data">
        <b-button @click="editIndustry(data.item)" variant="link">Edit</b-button>
      </template>
    </b-table>
    <b-modal id="modal-industry-new" ref="modalIndustry"
      hide-header-close no-close-on-esc no-close-on-backdrop
      :busy="submitting"
      :title="industry.id > 0 ? 'Edit industry ID: ' + industry.id : 'Add new industry'"
      @ok="saveIndustry">
      <b-form @submit.prevent="saveIndustry">
        <!-- NAME -->
        <b-form-group label="Name" label-for="form-industry-name">
          <b-form-input
            id="form-industry-name"
            v-model="industry.name"
            type="text"
            placeholder="Enter industry name"
            required maxlength="255"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import api from '@/api'
import slugify from '@/plugins/slugify'

export default {
  data: () => ({
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'name',
        label: 'Name'
      },
      {
        key: 'date_added',
        label: 'Date Added',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    industries: [],
    industry: {
      id: 0,
      name: '',
      url: '',
      shown: true
    },
    submitting: false
  }),
  mounted () {
    this.getIndustries()
  },
  methods: {
    getIndustries: async function () {
      let industries = await api.admin.portfolio.getClientIndustries()
      if (industries) {
        this.industries = industries
      }
    },
    newIndustry: function () {
      this.industry = {
        id: 0,
        name: '',
        url: '',
        shown: true
      }
      this.$refs['modalIndustry'].show()
    },
    editIndustry: function (industry) {
      this.industry = industry
      this.$refs['modalIndustry'].show()
    },
    saveIndustry: async function (ev) {
      ev.preventDefault()
      this.submitting = true
      this.industry.url = slugify(this.industry.name)
      let industry_id = await api.admin.portfolio.saveIndustry(this.industry)
      if (industry_id > 0) {
        this.getIndustries()
        this.$refs['modalIndustry'].hide()
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
</style>
