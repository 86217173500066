<template>
  <b-table hover :items="clients" :fields="fields">
    <template #cell(logos)="data">
      <img v-if="data.item.logoFiles.length > 0" :src="data.item.logoFiles[0].thumb_url" style="width: 160px;" alt="">
      <br>
      <img v-if="data.item.logoFiles.length > 1" :src="data.item.logoFiles[1].thumb_url" style="width: 160px;" alt="">
    </template>
    <template #cell(image_thumb_url)="data">
      <img :src="data.item.image_thumb_url" style="width: 160px;" alt="">
    </template>
    <template #cell(name)="data">
      {{data.item.name}}
      <br>
      {{data.item.subname}}
    </template>
    <template #cell(actions)="data">
      <b-button :href="`/portfolio/${data.item.id}`" target="_blank" variant="link">View</b-button>
      <b-button :href="`edit/${data.item.id}`" variant="link">Edit</b-button>
      <b-button @click="toggleClient(data.item.id, 0)" variant="link">Hide</b-button>
    </template>
  </b-table>
</template>

<script>
import api from '@/api'

export default {
  data: () => ({
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'logos',
        label: 'Logo'
      },
      {
        key: 'image_thumb_url',
        label: 'Image'
      },
      {
        key: 'name',
        label: 'Name',
        sortable: true
      },
      {
        key: 'date_published',
        label: 'Published Date',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    clients: []
  }),
  mounted () {
    this.getClients()
  },
  methods: {
    getClients: async function () {
      let clients = await api.admin.portfolio.getClients()
      if (clients) {
        this.clients = clients
      }
    },
    toggleClient: async function (client_id, active) {
      let r = await api.admin.portfolio.setClientActive(client_id, active)
      if (r) {
        this.$router.go()
      }
    }
  }
}
</script>

<style scoped>
</style>
