<template>
  <div>
    <b-button @click="newCategory" variant="link">New Category</b-button>
    <b-table hover :items="categories" :fields="fields">
      <template #cell(actions)="data">
        <b-button @click="editCategory(data.item)" variant="link">Edit</b-button>
      </template>
    </b-table>
    <b-modal id="modal-category-new" ref="modalCategory"
      hide-header-close no-close-on-esc no-close-on-backdrop
      :busy="submitting"
      :title="category.id > 0 ? 'Edit category ID: ' + category.id : 'Add new category'"
      @ok="saveCategory">
      <b-form @submit.prevent="saveCategory">
        <!-- NAME -->
        <b-form-group label="Name" label-for="form-category-name">
          <b-form-input
            id="form-category-name"
            v-model="category.name"
            type="text"
            placeholder="Enter category name"
            required maxlength="255"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import api from '@/api'
import slugify from '@/plugins/slugify'

export default {
  data: () => ({
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'name',
        label: 'Name'
      },
      {
        key: 'date_added',
        label: 'Date Added',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    categories: [],
    category: {
      id: 0,
      name: '',
      url: ''
    },
    submitting: false
  }),
  mounted () {
    this.getCategories()
  },
  methods: {
    getCategories: async function () {
      let categories = await api.admin.portfolio.getClientCategories()
      if (categories) {
        this.categories = categories
      }
    },
    newCategory: function () {
      this.category = {
        id: 0,
        name: '',
        url: '',
        shown: true
      }
      this.$refs['modalCategory'].show()
    },
    editCategory: function (category) {
      this.category = category
      this.$refs['modalCategory'].show()
    },
    saveCategory: async function (ev) {
      ev.preventDefault()
      this.submitting = true
      this.category.url = slugify(this.category.name)
      let category_id = await api.admin.portfolio.saveCategory(this.category)
      if (category_id > 0) {
        this.getCategories()
        this.$refs['modalCategory'].hide()
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
</style>
