<template>
  <b-overlay :show="loading">
    <b-table hover :items="blogs" :fields="fields">
      <template #cell(thumb_url)="data">
        <img :src="data.item.thumb_url" style="width: 160px;" alt="">
      </template>
      <template #cell(title)="data">
        {{data.item.title}}
        <br>
        <b-link :href="`/blog/${data.item.url}`" target="_blank">{{`/blog/${data.item.url}`}}</b-link>
      </template>
      <template #cell(actions)="data">
        <b-button :href="`edit/${data.item.id}`" variant="link">Edit</b-button>
        <b-button @click="hideBlog(data.item.id)" variant="link">Hide</b-button>
        <b-button @click="deleteBlog(data.item.id)" variant="link">Delete</b-button>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import api from '@/api'

export default {
  data: () => ({
    loading: false,
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'thumb_url',
        label: 'Thumbnail'
      },
      {
        key: 'title',
        label: 'Title',
        sortable: true
      },
      {
        key: 'views_total',
        label: 'Total views',
        sortable: true
      },
      {
        key: 'date_published',
        label: 'Published Date',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    blogs: []
  }),
  mounted () {
    this.getBlogs()
  },
  methods: {
    getBlogs: async function () {
      this.loading = true
      let blogs = await api.admin.blog.getBlogs()
      if (blogs) {
        this.blogs = blogs
      }
      this.loading = false
    },
    hideBlog: async function (blog_id) {
      let r = await api.admin.blog.setBlogActive(blog_id, false)
      if (r) {
        this.$router.go()
      }
    },
    deleteBlog: async function (blog_id) {
      let r = await api.admin.blog.deleteBlog(blog_id)
      if (r) {
        this.$router.go()
      }
    }
  }
}
</script>

<style scoped>
</style>
