<template>
  <div>
    <b-form inline class="m-3">
      <label for="form-input-year">Year</label>
      <b-form-select class="ml-2 mr-3" id="form-input-year" v-model="form.year" :options="form.years">
      </b-form-select>
      <label for="form-input-month">Month</label>
      <b-form-select class="ml-2 mr-3" id="form-input-month" v-model="form.month" :options="form.months">
      </b-form-select>
      <b-button @click="getBlogs">Go</b-button>
    </b-form>
    <b-overlay :show="loading">
      <b-table hover :items="blogs" :fields="fields">
        <template #cell(thumb_url)="data">
          <img :src="data.item.thumb_url" style="width: 160px;" alt="">
        </template>
        <template #cell(title)="data">
          {{data.item.title}}
          <br>
          <b-link :href="`/blog/${data.item.url}`" target="_blank">{{`/blog/${data.item.url}`}}</b-link>
        </template>
        <template #cell(actions)="data">
          <b-button :href="`edit/${data.item.id}`" variant="link">Edit</b-button>
          <b-button @click="hideBlog(data.item.id)" variant="link">Hide</b-button>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data: () => ({
    loading: false,
    form: {
      year: 2022,
      month: 1,
      years: [
        { value: 2024, text: '2024' },
        { value: 2023, text: '2023' },
        { value: 2022, text: '2022' },
        { value: 2021, text: '2021' },
        { value: 2020, text: '2020' }
      ],
      months: [
        { value: 1, text: 'January' },
        { value: 2, text: 'February' },
        { value: 3, text: 'March' },
        { value: 4, text: 'April' },
        { value: 5, text: 'May' },
        { value: 6, text: 'June' },
        { value: 7, text: 'July' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'October' },
        { value: 11, text: 'November' },
        { value: 12, text: 'December' }
      ]
    },
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'thumb_url',
        label: 'Thumbnail'
      },
      {
        key: 'title',
        label: 'Title',
        sortable: true
      },
      {
        key: 'views_monthly',
        label: 'Views',
        sortable: true
      },
      {
        key: 'date_published',
        label: 'Published Date',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    blogs: []
  }),
  mounted () {
    this.setCurrentMonth();
  },
  methods: {
    setCurrentMonth: function () {
      this.form.year = new Date().getFullYear();
      this.form.month = new Date().getMonth() + 1;

      this.getBlogs();
    },
    getBlogs: async function () {
      this.loading = true
      let blogs = await api.admin.blog.getMonthlyViews(this.form.year, this.form.month)
      if (blogs) {
        this.blogs = blogs
      }
      this.loading = false
    },
    hideBlog: async function (blog_id) {
      let r = await api.admin.blog.setBlogActive(blog_id, false)
      if (r) {
        this.$router.go()
      }
    }
  }
}
</script>

<style scoped>
</style>
