<template>
  <div>
    <b-button @click="newSector" variant="link">New Sector</b-button>
    <b-table hover :items="sectors" :fields="fields">
      <template #cell(actions)="data">
        <b-button @click="editSector(data.item)" variant="link">Edit</b-button>
      </template>
    </b-table>
    <b-modal id="modal-sector-new" ref="modalSector"
      hide-header-close no-close-on-esc no-close-on-backdrop
      :busy="submitting"
      :title="sector.id > 0 ? 'Edit sector ID: ' + sector.id : 'Add new sector'"
      @ok="saveSector">
      <b-form @submit.prevent="saveSector">
        <!-- NAME -->
        <b-form-group label="Name" label-for="form-sector-name">
          <b-form-input
            id="form-sector-name"
            v-model="sector.name"
            type="text"
            placeholder="Enter sector name"
            required maxlength="255"
          ></b-form-input>
        </b-form-group>
        <!-- SHOWN -->
        <b-form-checkbox switch v-model="sector.shown" value="1" unchecked-value="0">Show in client categories</b-form-checkbox>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import api from '@/api'
import slugify from '@/plugins/slugify'

export default {
  data: () => ({
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'name',
        label: 'Name'
      },
      {
        key: 'date_added',
        label: 'Date Added',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    sectors: [],
    sector: {
      id: 0,
      name: '',
      url: '',
      shown: 1
    },
    submitting: false
  }),
  mounted () {
    this.getSectors()
  },
  methods: {
    getSectors: async function () {
      let sectors = await api.admin.portfolio.getClientSectors()
      if (sectors) {
        this.sectors = sectors
      }
    },
    newSector: function () {
      this.sector = {
        id: 0,
        name: '',
        url: '',
        shown: 1
      }
      this.$refs['modalSector'].show()
    },
    editSector: function (sector) {
      this.sector = sector
      this.$refs['modalSector'].show()
    },
    saveSector: async function (ev) {
      ev.preventDefault()
      this.submitting = true
      this.sector.url = slugify(this.sector.name)
      let sector_id = await api.admin.portfolio.saveSector(this.sector)
      if (sector_id > 0) {
        this.getSectors()
        this.$refs['modalSector'].hide()
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
</style>
